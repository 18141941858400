<template>
    <section class="flex search-bar">
        <div class="input-container" style="width: 170.36px">
            <label> Name </label>
            <input v-model.trim="search.fullName" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 170.36px">
            <label> Company </label>
            <input v-model.trim="search.company" @keyup.enter="triggerSearch" />
        </div>

        <div style="width: 130px">
            <SelectNested v-model="range" :options="ranges" title="Effective Range" class="max-height-1000px" />
        </div>
        <div style="width: 130px">
            <Date v-model="search.effectiveFrom" title="Effective From" ref="effectiveFrom" />
        </div>

        <div style="width: 130px">
            <Date v-model="search.effectiveTo" title="Effective To" ref="effectiveTo" />
        </div>

        <CheckBoxOptional v-model="search.newBusiness" :title="'New Business'" />
        <!-- <CheckBoxOptional v-model="search.isBusiness" :title="'Is Business'" /> -->
        <div class="input-container" style="width: 170.36px">
            <label> Notes </label>
            <input v-model.trim="search.notes" @keyup.enter="triggerSearch" />
        </div>
        <div class="input-container" style="width: 159.19px">
            <button class="reset w-full" @click="resetSearch">Reset</button>
        </div>
        <div class="input-container" style="width: 159.19px">
            <button class="search w-full" @click="triggerSearch">Search</button>
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs';

import CheckBoxOptional from '../../components/CheckBoxOptional';
import Date from '@/components/Date';
import Select from '@/components/Select';
import SelectNested from '@/components/SelectNested';

export default {
    components: {CheckBoxOptional, Date, Select, SelectNested},
    data() {
        return {
            ranges: ['', {title: 'Period', options: ['This month', 'Last month', 'This year', 'Last year']}, {title: 'Exact', options: ['This month', 'Last month', 'This year', 'Last year']}],
        };
    },

    computed: {
        search() {
            return this.$store.getters['policies/getSearch'];
        },

        range: {
            get() {
                return this.search.range;
            },
            set(v) {
                const set = {
                    range: v,
                };

                // clone string
                v = v.toLowerCase();

                if (v) {
                    let effectiveTo;
                    let effectiveFrom;
                    // No need to add timezone, by default dayjs treat date as local
                    if (v === 'exact - this month') {
                        effectiveFrom = dayjs().date(1).format('M/D/YYYY');
                        effectiveTo = dayjs().date(dayjs().daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - this year') {
                        effectiveFrom = dayjs().dayOfYear(1).format('M/D/YYYY');
                        effectiveTo = dayjs().dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'exact - last month') {
                        effectiveFrom = dayjs().subtract(1, 'month').date(1).format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'month').date(dayjs().subtract(1, 'month').daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - last year') {
                        effectiveFrom = dayjs().subtract(1, 'year').dayOfYear(1).format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'year').dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'period - this month') {
                        effectiveFrom = dayjs().format('M/D/YYYY');
                        effectiveTo = dayjs().add(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last month') {
                        effectiveFrom = dayjs().subtract(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                        effectiveTo = dayjs().format('M/D/YYYY');
                    } else if (v === 'period - this year') {
                        effectiveFrom = dayjs().format('M/D/YYYY');
                        effectiveTo = dayjs().add(1, 'year').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last year') {
                        effectiveFrom = dayjs().subtract(1, 'year').format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'days').format('M/D/YYYY');
                    }

                    set.effectiveFrom = effectiveFrom;
                    set.effectiveTo = effectiveTo;
                }

                this.$store.commit('policies/setSearch', set);
            },
        },
    },

    async mounted() {
        window.history.replaceState({}, document.title, window.location.pathname);
        let params = {};
        params = {...this.search, ...params};
        this.$store.dispatch('policies/fetchTableRows', params);
    },

    methods: {
        async triggerSearch() {
            const effectiveFromError = this.$refs.effectiveFrom.error;
            const effectiveToError = this.$refs.effectiveTo.error;

            if (effectiveFromError || effectiveToError) {
                return;
            }

            this.setQueryParams(this.search);
            await this.$store.dispatch('policies/fetchTableRows', {});
        },
        resetSearch() {
            this.$store.commit('policies/resetSearch');
            window.history.replaceState({}, document.title, window.location.pathname);
        },
    },
};
</script>

<style lang="scss" scoped></style>
