<template>
    <section  class="table-section">
        <table>
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>New</th>
                    <th style="min-width: 104px">Effective</th>
                    <th style="min-width: 104px">Expires</th>
                    <th>Cancelled</th>
                    <th>Renewed</th>
                    <th>Carrier</th>
                    <th>Type</th>
                    <th>Premium</th>
                    <th>Adjusted</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <tr v-for="(row, i) in rows" :key="i">
                    <td style="padding-left: 0; width: 100px">
                        <div class="table-icons">
                            <a href="#" class="edit" title="Edit Policy" @click.prevent="showEdit(row)">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-.9 0 24 24" style="background: #047fff">
                                    <g clip-path="url(#clip0_46_43592)">
                                        <path
                                            d="M14.4803 7.38745C15.2954 8.1943 16.0289 8.91882 16.8277 9.7092C15.1812 11.3558 13.6 13.1013 11.8394 14.6162C11.3341 15.0608 10.2907 14.8961 9.49196 14.962C9.42675 14.962 9.24744 14.6162 9.24744 14.4515C9.29634 13.2989 9.49196 12.2615 10.4701 11.4052C11.8883 10.1538 13.1435 8.73769 14.4803 7.38745Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M15.074 6.98489C16.4108 5.27239 17.6986 5.43705 18.4811 7.19895C18.8397 8.0058 18.1551 8.38453 17.7149 8.81265C17.2585 9.27371 17.0628 8.71385 16.8183 8.49979C16.2152 8.0058 15.6446 7.47888 15.074 6.98489Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M14.21 13.1099V15.8927V15.9092C11.8137 15.9092 9.38473 15.9092 6.89058 15.9092C6.89058 13.4392 6.89058 11.0022 6.89058 8.48286C8.39033 8.48286 9.85748 8.417 11.3083 8.49933C12.3353 8.56519 12.8081 7.98887 13.3134 7.23142C13.1667 7.16555 13.0689 7.08322 12.9874 7.08322C10.8356 7.06676 8.70006 7.05029 6.54824 7.08322C5.89617 7.09969 5.53754 7.54428 5.53754 8.23587C5.53754 10.9034 5.53754 13.5545 5.53754 16.222C5.53754 16.9466 5.94508 17.3582 6.64605 17.3582C9.28692 17.3747 11.9115 17.3912 14.5524 17.3253C14.911 17.3088 15.5305 16.8148 15.5631 16.502C15.5794 16.3044 15.5957 16.1068 15.5957 15.9092H15.6283V12.2866H15.0251L14.21 13.1099Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_46_43592">
                                            <rect width="13.0413" height="11.5923" fill="white" transform="translate(5.53754 5.78223)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                            <a href="#" class="delete" title="Delete Policy" @click.prevent="showDelete(row)">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox=".6 -.9 24 24" style="background: #9f9f9f">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.9797 5.65798H17.5697V7.138H7.20953V5.65798H9.79957L10.5396 4.91797H14.2396L14.9797 5.65798ZM7.94954 16.7581V7.87801H16.8297V16.7581C16.8297 17.5722 16.1637 18.2382 15.3497 18.2382H9.42957C8.61555 18.2382 7.94954 17.5722 7.94954 16.7581Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                            <a href="#" @click.prevent="openFolder(row)" class="file"
                                ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.2955" cy="12.2373" r="11.4634" fill="#FF764A" />
                                    <path
                                        d="M19.057 10.3043C19.0487 10.1631 19.032 10.0219 18.9987 9.88921C18.7655 9.02475 18.0575 8.4898 17.1538 8.4898C15.4547 8.4898 13.7514 8.4898 12.0522 8.4898C11.9689 8.4898 11.8856 8.4898 11.7857 8.4898C11.7815 8.41277 11.7774 8.36142 11.7774 8.31434C11.7524 7.13319 10.9819 6.35432 9.83668 6.35004C9.03708 6.35004 8.23749 6.35004 7.44206 6.35004C6.31347 6.35432 5.53053 7.16315 5.52637 8.31862C5.52637 8.98195 5.52637 9.64528 5.52637 10.3043H19.057Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M5.52637 10.6303C5.52637 12.4705 5.52637 14.315 5.52637 16.1552C5.52637 17.3107 6.31763 18.1195 7.44206 18.1238C10.6779 18.1281 13.9096 18.1281 17.1455 18.1238C18.2699 18.1238 19.0612 17.3149 19.0612 16.1595C19.0653 14.315 19.0653 12.4748 19.0612 10.6303H5.52637Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>

                            <a href="#" class="add" title="Add Policy" @click.prevent="addPolicy(row)">
                                <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11.4913" cy="11.9747" r="11.4634" fill="rgb(164, 82, 177)" />
                                    <g clip-path="url(#clip0_433_1639)">
                                        <path
                                            d="M11.7483 5.78955C12.775 6.76843 13.7715 7.77696 14.8435 8.71134C15.2209 9.03764 15.3115 9.36393 15.2813 9.79404C15.2511 10.2538 15.2813 10.7284 15.2813 11.2475C15.0549 11.114 14.8435 11.025 14.6774 10.8916C14.617 10.8471 14.6019 10.7433 14.6019 10.6543C14.5868 10.328 14.6019 10.0165 14.6019 9.66056C14.4811 9.66056 14.3905 9.66056 14.2848 9.66056C13.4544 9.66056 12.624 9.66056 11.7936 9.66056C11.386 9.66056 11.2803 9.55674 11.2803 9.14146C11.2803 8.35539 11.2803 7.58415 11.2803 6.79809C11.2803 6.69427 11.2803 6.60528 11.2803 6.48663C9.60437 6.48663 7.94356 6.48663 6.26765 6.48663C6.26765 9.98685 6.26765 13.4871 6.26765 17.0169C6.37334 17.0169 6.46393 17.0169 6.55452 17.0169C8.18513 17.0169 9.81575 17.0169 11.4615 17.0169C11.6879 17.0169 11.854 17.0614 11.9597 17.2691C12.0352 17.4026 12.156 17.5212 12.2768 17.6844C12.156 17.6844 12.0654 17.6992 11.9899 17.6992C10.1177 17.6992 8.24552 17.6992 6.37334 17.6992C5.73921 17.714 5.52783 17.5212 5.52783 16.9131C5.52783 13.4722 5.52783 10.0313 5.52783 6.59045C5.52783 6.20483 5.64862 5.9527 5.99588 5.78955C7.91336 5.78955 9.83085 5.78955 11.7483 5.78955ZM14.0886 8.94865C13.3638 8.28123 12.6995 7.67314 11.9748 7.00573C11.9748 7.68797 11.9748 8.31089 11.9748 8.94865C12.6693 8.94865 13.3487 8.94865 14.0886 8.94865Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M9.86113 11.4404C10.5406 11.4404 11.22 11.4404 11.8843 11.4404C12.0655 11.4404 12.2165 11.47 12.2467 11.6777C12.2769 11.9001 12.1108 11.9446 11.9447 11.9595C11.8843 11.9743 11.839 11.9743 11.7786 11.9743C10.4651 11.9743 9.15151 11.9743 7.83796 11.9743C7.64168 11.9743 7.4756 11.9298 7.4907 11.6925C7.4907 11.4552 7.65678 11.4255 7.85306 11.4255C8.50229 11.4404 9.18171 11.4404 9.86113 11.4404Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M11.0237 15.3262C10.5858 15.3262 10.1631 15.3262 9.75544 15.3262C9.09112 15.3262 8.44189 15.3262 7.77756 15.3262C7.59638 15.3262 7.4756 15.2817 7.4605 15.074C7.4454 14.8812 7.56619 14.7922 7.74737 14.7922C8.78915 14.7922 9.83093 14.7922 10.8878 14.7922C10.9331 14.9702 10.9784 15.1334 11.0237 15.3262Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M10.7066 13.1014C10.7066 13.2942 10.7066 13.4574 10.7066 13.6354C10.3896 13.6354 10.0876 13.6354 9.78562 13.6354C9.1062 13.6354 8.44188 13.6354 7.76245 13.6354C7.59637 13.6354 7.47559 13.5612 7.47559 13.3832C7.47559 13.2053 7.56618 13.1014 7.76245 13.1014C8.72874 13.1014 9.69503 13.1014 10.7066 13.1014Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.94008 10.2686C8.56262 10.2686 8.17007 10.2686 7.79261 10.2686C7.61143 10.2686 7.46045 10.2241 7.46045 10.0016C7.46045 9.80884 7.59633 9.74951 7.77751 9.74951C8.54753 9.74951 9.33264 9.74951 10.1027 9.74951C10.2838 9.74951 10.4197 9.80884 10.4197 10.0016C10.4197 10.2093 10.2838 10.2686 10.0876 10.2686C9.71009 10.2686 9.31754 10.2686 8.94008 10.2686Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.38141 8.69653C8.17003 8.69653 7.95866 8.69653 7.74728 8.69653C7.5661 8.69653 7.46041 8.62237 7.46041 8.4444C7.44532 8.26642 7.551 8.17743 7.71709 8.1626C8.15494 8.1626 8.59279 8.1626 9.03064 8.1626C9.21182 8.1626 9.3175 8.28125 9.28731 8.42956C9.25711 8.53338 9.13633 8.65204 9.03064 8.66687C8.81926 8.72619 8.60789 8.69653 8.38141 8.69653Z"
                                            fill="white"
                                        />
                                    </g>
                                    <rect x="12.7244" y="15.5803" width="1.05911" height="4.32767" transform="rotate(-90 12.7244 15.5803)" fill="white" />
                                    <rect x="14.3586" y="12.887" width="1.05911" height="4.32767" fill="white" />
                                    <defs>
                                        <clipPath id="clip0_433_1639">
                                            <rect width="11.9277" height="12.3694" fill="white" transform="translate(5.52783 5.78955)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </td>
                    <td @click="showEditCustomer(row)" class="cursor-pointer">
                        <div class="highlight whitespace-nowrap">{{ row.fullName }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.company }}</div>
                    </td>
                    <td>
                        <div v-if="row.newBusiness" class="flex justify-center w-full"><span v-html="xOrCheck(row.newBusiness)"></span></div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.effective }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.expires }}</div>
                    </td>
                    <td>
                        <div class="text-red-400 whitespace-nowrap">{{ row.cancelled }}</div>
                    </td>
                    <td>
                        <div class="flex justify-center w-full"><span v-html="xOrCheck(row.renewed)"></span></div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.carrier }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ row.type }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ toDollar(row.premium) }}</div>
                    </td>
                    <td>
                        <div  class="whitespace-nowrap">{{ addPercent(row.adjusted) }}</div>
                    </td>
                    <td >
                        <div  :title="row.notes" class="table-notes">{{ row.notes }}</div>
                    </td>
                </tr>
            </tbody>
            <template v-else>
                <tr>
                    <td colspan="19">
                        <div class="text-black text-center py-4">No data found</div>
                    </td>
                </tr>
            </template>
        </table>
    </section>
</template>

<script>
export default {
    props: ['rows'],
    
    name: 'Table',

    methods: {
        showEdit(row) {
            this.$store.commit('policies/showEdit', row);
        },

        showDelete(row) {
            this.$store.commit('policies/showDelete', row);
        },

        moveToCustomer(row) {
            location.assign('/dashboard/customers?fullName=' + encodeURIComponent(row.fullName));
        },

        addPolicy({ customerId, address, company, fullName }) {
            this.$store.commit('policies/showAdd', {customerId, address, company, fullName, newBusiness: false});
        },

       async openFolder({customerId}) {
            const data = await this.$store.dispatch('customers/readTableRow', {customerId});
            this.$store.dispatch('customers/openFolder', data)
        },

        async showEditCustomer({customerId}) {
            const data = await this.$store.dispatch('customers/readTableRow', {customerId});
            this.$store.commit('customers/setDetails', data);
            this.$store.commit('policies/showCustomerEdit');
        },
    },
};
</script>
<style scoped lang="scss"></style>
