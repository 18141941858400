<template>
    <Modal type="Edit" @submitData="submitData" />
</template>

<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },
    methods: {
        async submitData() {
            const details = this.$store.state.customers.modal.details;
            const id = details.id;
            
            this.loader();
            await this.$store.dispatch('customers/updateTableRow', details);
            this.loader(false);
            this.hideModals();


            // Check if fullName and company exist
            const params = Object.fromEntries(new URLSearchParams(location.search));

            let obj = {}

            if('company' in params) {
                obj.company  = details.company
            }

            if('fullName' in params) {
                obj.fullName  = [details.firstName, details.lastName].join(' ');
            }

            if('company' in params || 'fullName' in params) {
                this.$store.commit(`${this.$route.name}/setSearch`, obj)
                this.setQueryParams(obj)
            }

            if(this.$route.name === 'policies') {
              await this.$store.dispatch('policies/fetchTableRows');
            }

            if(this.$route.name === 'performance') {
              await this.$store.dispatch('performance/fetchTableRows');
            }

             if(this.$route.name === 'customers') {
              await this.$store.dispatch('customers/fetchTableRows');
            }

             if(this.$route.name === 'renewals') {
              await this.$store.dispatch('renewals/fetchTableRows');
            }

        },
    },
};
</script>
